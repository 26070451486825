
@keyframes hoverAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
@keyframes fadeInFromBottom {
  from {
      opacity: 0;
      transform: translateY(80%);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
@keyframes fadeInLeftToRight {
  from {
      opacity: 0;
      transform: translateX(20%); /* Start from right */
  }
  to {
      opacity: 1;
      transform: translateX(-20%); /* End at left */
  }
}
@keyframes fadeInRightToLeftSmall {

    from {
        opacity: 0;
        transform: translateX(-100%); /* Start from right */
    }
    to {
        opacity: 1;
        transform: translateX(0); /* End at left */
    }

}
@keyframes typing {
  from { width: 0; }
  to { width: 14%; }
}


/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgb(0, 0, 0); }
}
@keyframes fadeIn {
  from {
      opacity: 0;

  }
  to {
      opacity: 1;

  }
}

.content{
  height: 100vh;

}


*{
  margin:0;
  font-family: "Fredoka", sans-serif;
  font-weight: 400;
  font-style: normal;
  color:#3E48CC;

  /* color: #F9D190; */

}

/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*-----------------------------------------IO-----------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
.section-splitter {
  position: relative;
  height: 100px;
  background: linear-gradient(180deg, #ffffff, #3E48CC);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}


.section-splitter::before {
  left: -100px;
}

.section-splitter::after {
  right: -100px;
}


/* .live-indicator {
  color:#F9D190;
  position: absolute;
  display: flex;
  align-items: center;
  font-family: Arial,3sans-serif;
  margin-left: 81%;
  margin-top: 0.3%;
}

.live-dot {
  height: 10px;
  width: 10px;
  background-color: rgb(9, 255, 0);
  border-radius: 50%;
  margin-right: 5px;
  animation: fadeInOut 2s linear infinite;
} */

@keyframes fadeInOut {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}
.gif-container {
  
    position: absolute;
    
    
    background-color: #BDBDBD;
    left: 0; /* Align it to the left */
    right: 0; /* Stretch across the full width of the container */
    z-index: 20;
    bottom: 0; /* Position at the bottom of the parent */
   
  
  
}

.moving-gif {
  background-color: #BDBDBD;
  position: absolute;
  height: 40px;

  width: 40px;
  animation: moveGif 9s linear infinite;
}

@keyframes moveGif {
  0%, 100% {
    transform: translateX(0) scaleX(1);
  }
  49% {
    transform: translateX(calc(100vw - 100px)) scaleX(1);
  }
  50% {
    transform: translateX(calc(100vw - 100px)) scaleX(-1);
  }
  99% {
    transform: translateX(0) scaleX(-1);
  }
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid rgb(0, 0, 0); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  letter-spacing: .25em; /* Adjust as needed */
  animation:
    typing 5.5s steps(18, end),
    blink-caret .75s step-end infinite;
}

.fade-in-right {
  animation: fadeInRightToLeft 3s ease forwards;
}
.fade-in-right-small {
  animation: fadeInRightToLeftSmall 3s ease forwards;
}
.fade-in-left {
  animation: fadeInLeftToRight 3s ease forwards;
}
.nav{
  display: block;

  display: flex;
  align-items: center;
  flex-direction: row;
  list-style: none;
  position: absolute;
  gap:50px;
  background-color: transparent;


  padding: 1%;

}
.dexes {
  position: fixed;
  z-index: 20;
  width: 17%;  /* Width of the container */
  background-color: transparent;
  color: white;
  
  
  gap: 1%;
  top: 4.5%; /* Position the top edge of the element at the middle of the screen */
  left: 63%; /* Position the left edge of the element at the middle of the screen */
  transform: translate(-50%, -50%); /* Shift the element back by half its width and height */

  display: flex;
  align-items: center; /* Center items vertically within the container */
  justify-content: center; /* Center items horizontally within the container */
}


.dex {
  height: 45px; /* Height for dex */
 
}

.dexscreener {
  height: 35px; /* Smaller height for dexscreener */
 
  border-radius: 0 15px 0 15px; /* Smooth corners on right side */
}


.socials-nav{

  
  display: flex;
  flex-direction: row;
  gap: 25px;
  font-size: 28px;
  list-style: none;
  position: absolute;
  right: 160px;
  top: 20px;
  
}
.socials-li{
  color: white;
  font-family: "Faster One", system-ui;
  text-decoration: none;
  list-style: none;
}
.link{
  /* color: #3E48CC; */
  color: white;
  text-decoration: none;
  font-size: 30px;
  text-transform: uppercase;
  font-family: "Faster One", system-ui;
  font-weight: 400;
  font-style: normal;

  /* z-index: 20; */
}
/* .catpaw:hover{
  cursor: url('./img/cursor.cur'), auto;
} */
.welcome-bg{
  position: absolute;
  background-image: url("./img/welcomeAccelerate.png");
  background-repeat: no-repeat;
  height: 1500px;
  width: 1200px;
  left: 700px;
  background-size: 100%;
  background-position: 10px 5px;
  z-index: -4;
}
.have-you-ever-txt{
  position: absolute;
  margin-top: 330px;
  margin-left: 350px;
  color: black;
  font-family: sans-serif;
  font-size: 20px;
}

.buy-btn{
  /* border: 1px solid red; */
  /* background-image: url("./img/ring.png");
  background-repeat: no-repeat; */
  cursor: pointer;
  /* background-size: 80%;
  background-position: 10px 5px; */
  position: absolute;
  font-family: "Faster One", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  right: 150px;
  height:40px;
  width: 180px;
  top: 20px;
  border: none;
  border-radius: 25px;
  border: 3px solid white;
  background-color: transparent;
  color: white;
}

/* .buy-btn:hover{
  cursor: url('./img/cursorbuy.cur'), auto;
} */

.mob-buy-btn{
  display: none;
}

.io-logo{
  height: 70px;
  border-radius: 50px;
  position: absolute;
  right: 20px;
  top: 10px
}

.main-land{
  background-image: url('./img/welcomeBackground.png');
  background-color: #3676edb2;
  background-repeat: no-repeat;
  background-size: 30%;
  height: 100vh;
  background-position:0% 60%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 660px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
   /* box-shadow: rgb(0, 0, 0) 10px 135px 50px 10px inset;  */
}

.content-header{
  color: transparent;
}
.io-header{
  position: relative;
  font-family: "Faster One", system-ui;
  font-weight: 400;
  font-style: normal;
  color:white;

  /* color: #F9D190; */
  /* color: #EC6906; */
  /* width: 110%; */
  margin-left: 30%;
  margin-top: 200px;
}
.welcome-img{
  background-image: url("./img/sanic.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 450px;
  width: 450px;

  margin-left: 70%;

}
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*-------------------------------------SOCIALS----------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/

#Socials{
  position: absolute;
  top: 0;
  left: 0;
}
/* Apply this style to the parent container of .collabs-container */
.social {
  height: 150vh; 
  background-color: rgba(18, 18, 85, 0.932);
  position: relative;
  display: flex;
  flex-direction: column;
 
  align-items: center; /* Center vertically */
 
  
}
.social-header{
  font-family: "Bangers", system-ui;
  margin-bottom: 30px;
  color: white;
  font-size: 50px;
  margin-top: 125px;
}
.bridge-header{
  font-family: "Bangers", system-ui;
  margin-bottom: 120px;
  color: white;
  font-size: 50px;
  margin-top: 100px;
}
.howtobuy-container{
  display: flex;
  flex-direction: row;
  gap:20px;
  
  
}
.howtobuy-step{
  border: 1px solid white;
  height: 300px;
  border-radius: 0 15px 0 15px;
  width: 400px;
  box-shadow: #00042e 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

 
 
}
.bridge{
  height: 100px;
  text-align: center;
}
.howtobridge-txt{
  color: white;
  padding: 22px;
}
.howtobridge-iftxt{
  color: white;
  position: absolute;
  text-align: center;
  top:700px;
  font-size: 18px;
}
.howtobridge-iftxt a{
  color: white;
}
.howtobuy-header{
  text-align: center;
  font-family: "Bangers", system-ui;
  color: white;
  margin-top: 20px;
  font-size: 28px;
}
.howtobuy-txt{
  color: white;
  padding: 40px 40px 22px 40px;
}
.howtobuy-list{
  margin-left:90px;
  
}
.howtobuy-list li a{
  
  color: white;
  text-decoration: none ;
}
.howtobuy-list li a:hover{
  color: #444bad;
}
/* Adjust the container of the Wormhole Connect widget */





.urnotfast {
  position: absolute;       /* Position relative to the nearest positioned ancestor */
  background-image: url("./img/urnotfast.png");
  background-size: 110%;    /* Cover the div fully with the background */
  background-repeat: no-repeat;
  height: 450px;            /* Fixed height */
  width: 450px;             /* Fixed width */

  margin: 0;
  right: 50px;                 /* Align to the right side of the container */
  bottom: 0;                /* Align to the bottom of the container */
  z-index: 10;              /* Ensure it's above other content (adjust as necessary) */
}
.uwu {
  position: absolute;           /* Position relative to the nearest positioned ancestor */
  background-image: url("./img/UWU.png");
  background-size: cover;       /* Cover the div fully with the background */
  background-repeat: no-repeat;
  top: 0;                       /* Align to the top of the container */
  left: 0;                      /* Align to the left of the container */
  width: 1000px;                 /* Specify width */
  height: 100vh;                /* Specify height */
  z-index: -1;                  /* Ensure it's above other content (adjust as necessary) */
}

  
.collabs-container {
 
  font-family: "Bangers", system-ui;
  display: flex;
  width: 100%; /* Ensure it spans the full width of its parent */
  position: relative; /* If needed based on layout */

  flex-direction: row;
  /* margin-top: 10px; */
  margin-bottom: 0;
  justify-content: center;
  gap: 50px;
  overflow: hidden;
  z-index: 10;
}

.collabs-sort{
  
  display: flex;
  flex-direction: column;
 
}
.collab{
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  border:1px solid rgb(255, 255, 255);
  
  height: 50px;
  width: 200px;
  border-radius: 0 15px 0 15px;
  box-shadow: #ffffff73 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

}
.collab:hover{
  box-shadow: #ffffffa8 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.collab-txt{
  font-family: "Bangers", system-ui;
  font-weight: 400;
  font-style: normal;
  /* font-family: "Faster One", system-ui; */
  color: white;
  font-size: 18px;
  text-decoration: none;
  margin-top: 15px;
  margin-left: 15px;
}
.collabOne{
  border-radius: 50%;
  background-image: url(./img/collabOne.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 10px;
  
}
.collabTwo{
  border-radius: 50%;
  background-image: url(./img/coinmarketcap.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 10px;

}
.collabThree{
  border-radius: 50%;
  background-image: url(./img/collabThree.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 10px;
  
}
.collabFour{
  border-radius: 50%;
  background-image: url(./img/collabFour.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 10px;

}
.collabFive{
  border-radius: 50%;
  background-image: url(./img/collabFive.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 10px;
  
}
.collabSix{
  border-radius: 50%;
  background-image: url(./img/collabSix.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 10px;
}
.collabSeven{
  border-radius: 50%;
  background-image: url(./img/collabSeven.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 10px;
  
}
.collabEight{
  border-radius: 50%;
  background-image: url(./img/collabFour.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;

}

/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*--------------------------------------TOKENOMICS------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/

.tokenomics{
  background-color: #3E48CC;
  height: 100%;
  /* display: flex;
  flex-direction: row; */
}

#Roadmap{
  position: absolute;
  /* margin-top: -50%; */
  /* height: ; */
}
.tokenomics-txt, .tokenomics-header{
  color: white;
  font-size: 22px;
  width: 250px;
}

.tokenomics-total{
  position: absolute;
  margin-top: -42%;

}
.tokenomics-content{

  display: flex;
  flex-direction: row;
  border: 3px solid white;
  margin-bottom: 20px;
  border-radius: 0 15px 0 15px;
  padding: 3px 0px 5px 0px;
  margin-left: 680px;
  width: 60%;
  box-shadow: rgb(25, 31, 92) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

}


.tokenomics-img-one{
  background-image: url("./img/samic.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 115px;
  width: 195px;

  margin-top: 25px;
  margin-right: 70px;




}
.tokenomics-img-two{

  background-image: url("./img/creepy.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100px;
  width: 200px;

  margin-top: 25px;
  margin-right: 50px;


}
.tokenomics-img-three{

  background-image: url("./img/Sanic.webp");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 110px;
  width: 170px;

  margin-top: 25px;
  margin-right: 50px;
  /* margin-left: 10px; */


}
.tokenomics-img-four{

  background-image: url("./img/somic-scream.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 120px;
  width: 220px;

  margin-top: 30px;
  margin-right: 50px;



}
.tokenomics-header{
  font-family: "Bangers", system-ui;
  padding: 3.5% ;
}
.tokenomics-txt {
  font-size: 17px;
  padding: 0% 0% 0% 0%;
  padding-top:3%;
  width: 110%;
  margin-left: 2%;
}
.tokenomics-txt li{
  
  margin-top: 5px;
}
.t-txt-one{

  color: white;
 padding: .5%;
}
.t-txt-two{

  color: white;
  padding: .5%;
  width: 70%;
  
}
.t-txt-three{

  color: white;
 padding: .5%;
 width: 80%;
 
}
.t-txt-four{
  color: white;
  padding: .5%;
}

.tokenomics-img-bg {
  position: absolute;
  background-image: url("./img/soomic.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 625px;
  width: 625px;
  margin-left: 10px;
  margin-top: 5%;
  opacity: 0;
  z-index: 1;
}
.roadmap-chat-img{
  position: absolute;
  background-image: url("./img/chatsquare.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 500px;
  width: 700px;
  margin-top: 15%;
  z-index: 10;
  text-align: center;

}
.roadmap-txt{
  font-size: 90px;
  margin-top: 320px;

}

.img-container {
  position: relative;
  height: 945px; /* Adjust height as needed */
  overflow: hidden;
}

.animation-start {
  opacity: 1;
  animation: fadeInFromBottom 2s forwards;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}



.catpaw{

}

/* .wallet-btn{
  display: none;
  background-color: transparent;
  width: 150px;
  height: 40px;
  border-radius: 20px;
} */


.div-box{
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;

  /* background-color: #304256; */
  border-radius: 300px;
  box-shadow: rgba(0, 0, 0, 0.35) -40px 0px 36px -28px inset;
}

.io-boxes{
  display: flex;
  flex-direction: row;
  gap: 200px;
}

.io-txt p{
  color: #F19052;
}


.io-front{

  margin-top: 22%;
}

#io-font-txt{
  display: block;
  margin-top: 0.5%;
  font-size: 100px;
  padding-right: 3%;
}
.mob-tokenomics{
  display: none;
}

/* .gif-fade-one{
  background: linear-gradient(180deg, rgba(189,189,189,1) 0%, rgba(189,189,189,1) 40%, rgba(162,159,185,1) 61%, rgba(140,134,182,1) 71%, rgba(56,37,168,1) 100%);
  height: 50px;
  width: 100%;
} */
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*--------------------------------------HOW-IT-WORKS----------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
@keyframes moveToRight {
  from {

      transform: translateX(0%); /* Start from right */
  }
  to {

      transform: translateX(200%); /* End at left */
  }
}
.howitworks{

    position: relative;
    

    background-color: #2E1E8A;
    background-image: url('./img/howitworks-bg1.png');
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 0% ;
    z-index: 2;
    box-shadow: inset 0 0 35px 5px rgba(0,0,0,0.25), inset 0 2px 1px 1px rgba(255,255,255,0.9), inset 0 -2px 1px rgba(0,0,0,0.25);


  }
.fade-bg{
  position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #1f536bd3;
    z-index: 1;
}


 .howitworks-header{
  position: relative;
  margin-top: 3%;
  margin-left: 40px;
  color: white;
  z-index: 10;
}
.how-header{
  position: absolute;
  margin-left: 70px;
  margin-top:90px;
  color: white;

}
.howitworks-content{
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 10;
}
.how-sort {
  display: flex;
  flex-direction: column;
  margin-top: 130px;
  margin-left: -250px; /* Initially pushed to the left */
  transition: margin-left 2s ease;

}

.proposal-container:hover ~ .how-sort, .how-header {
  margin-left: 40px; /* Reset to 0 on hover to move it right */

}
.howitworks-wrapper{
  display: flex;
  flex-wrap:row;
  margin-bottom: 30px;


}
.howitworks-container{
  height: 210px;
  width: 300px;
  border-radius: 0 15px 0 15px;
  margin-left: 40px;
  border: 2px solid white;
  background-color: transparent;
  box-shadow: #00335ea2 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.howitworks-txt{
  color: white;
  padding: 20px;
  font-size: 18px;
  margin-top: 2%;
}
.hover-txt{
  font-family: "Bangers", system-ui;
  text-align: center;
  color: white;
  padding: 7%;
  font-size: 25px;
}
.proposal-container{
  height: 60px;
  width: 280px;
  margin-top: 20px;

  transition: all 0.5s ease;
  overflow: hidden;
}

.proposal-container:hover{
  height: 90%;
  width: 730px;


  cursor: pointer;
}


.proposal{
  /* background-image: url("./img/proposal.jpg"); */
  /* background-repeat: no-repeat;
   background-size: 80%;
    */
    color: white;
   height: 90%;

   font-size: 20px;
   width: 550px;
   margin-left: 75px;
   border-radius: 0 15px 0 15px;
  padding: 5px;

  transition: opacity 0.5s ease;
  opacity: 0;
}
.meme-div{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 40px;
  margin-bottom: 50px;
  transition: opacity 0.5s ease;
  opacity: 0;
}
.meme1{
    background-image: url("./img/then_dont_know_im_fast_last_meme.jpg");
  background-repeat: no-repeat;
   background-size: 100%;
   height: 320px;
   width: 320px;


}
.meme2{
background-image: url("./img/NAH_ID_WIN.png");
  background-repeat: no-repeat;
   background-size: 100%;
    height: 310px;
    width: 310px;

    margin-left: 10px;
}

.proposal-container:hover .proposal{
  opacity: 1;

}
.proposal-container:hover .meme-div{
  opacity: 1;

}
.token-dist-container{
  position: absolute;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 700px;
    margin-left: 1200px;
    margin-top: 20px;
    width: 400px;
 
    opacity: 1;
    transition: opacity 0.5s ease-out; /* Smooth transition for opacity */
}
.chart-container {
  position: relative;
  top: 0px;
  height: 350px; 
}

.pie-chart {
  position: absolute;
  top: 1px;  
  left: 0;
  right: 0;
  bottom: 0;
}
.chart-img{
  background-image: url("./img/hehe-removebg-preview.png");
  background-repeat: no-repeat;
   background-size: 90%;
   margin-top: 30px;
   height: 320px;
   width: 320px;
   margin-left: 200px;
}

/* .token-dist-ex{
  position: relative;
  background-image: url("./img/token_dist.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 300px;
  height: 60px;
  
  border-radius: 0 15px 0 15px;
  
}
.token-dist{
  position: relative;
  background-image: url("./img/Token_Distribution.png");
  background-repeat: no-repeat;
  background-size: 100%;
  /* width: 28%; 
  height: 450px;
  width: 450px;
 
  margin-top: 7%;
 
} */
.proposal-container:hover ~ .token-dist-container{
  opacity: 0;
    visibility: hidden; /* Hide the element after it fades out */
    position: absolute;
    transition: visibility 0s 0.5s, opacity 0.5s ease-out; 
}




/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*--------------------------------------AIRDROP-SEC-----------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
.last-container {
  position: relative;
  height: 100vh;
  background-image: url("./img/sanic-bg.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  opacity: 0; /* Start hidden */
  transition: opacity 2s linear; /* Apply the animation */
  z-index: 1;
}
.last-container.visible {
    opacity: 1; /* Transition to visible */
}


.airdrop-container{
  position: absolute;
  background-image: url("./img/chat2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 99%;
   width: 400px;
  height: 400px;
margin-top: 10%;
  margin-left: 60%;
  text-align: center;
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Add a transition for the fade-in effect */
  z-index: 10;
}
.airdrop-container.visible {
  opacity: 1; /* Show the chat bubble when the 'visible' class is added */
}
.claim-txt{
  padding-top: 100px;
  padding-left: 50px;
  color: black;
  width: 300px;
  z-index: 10;

}


.btn-letter{
  margin-top: 0;
  color: white;
}

.hidden-info {
  position: absolute;
  background-image: url("./img/chat1.png");
  background-repeat: no-repeat;
  background-size: 100%;

  display: none;  /* Initially hide the div */
  right: 800px;

  padding: 8px; /* Some padding */

  height: 200px;
  width: 200px;
  margin-top: 175px; /* Space between button and the div */
}
.hidden-txt{
  font-family: "Eater", serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  font-size: 32px;
  padding-top: 40px;
}

.claim-btn:hover + .hidden-info {
  display: block; /* Show the div when hovering over the button */
}
/*
.img-airdrop{
  background-image: url('./img/airdrop.webp');
  /* background-color: black;
  height: 600px;
  width: 600px;
  background-size: 100%;
  border-radius: 50%;
  margin-top: 150px;
  margin-left: 1000px;
  background-repeat: no-repeat;
} */

.socials{


    font-family: "Bangers", system-ui;
      margin: 0;
      margin-left: 3px;
      margin-right: 3px;
      color: rgb(10, 119, 221);
      text-decoration: none;
      
    
  margin-top: 5%;
  text-decoration: none;
}

/* .sneaky-img{
  position: absolute;
  background-image: url("./img/sneaky.jpg");

  background-size: 100%;
  background-repeat: no-repeat;
  height: 150px;
  width: 250px;
  right: 2%;
  margin-top: 31.5%;



} */
.rights{
  position: absolute;
  
  background-color: #BDBDBD;
  
 
  left: 0; /* Align it to the left */
  right: 0; /* Stretch across the full width of the container */
  z-index: 20;
  bottom: 0; /* Position at the bottom of the parent */
  text-align: center; /* Center text horizontally */
}

.rights-txt{
  background-color: transparent;
  position: absolute;
  z-index: 10;
  font-size: 18px;
  margin-top: 0.5%;
  margin-left: 1%;

}
/* .airdrop-container {
  margin-left: 30%;
  text-align: center;
  padding: 20px;
  border-radius: 50%;
  text-align: center;
  height: 55vh;
  width: 55vh;
  padding: 5%;
}
.airdrop-container h1{
  margin-top: 150px;
}

.airdrop-container input {
  margin-right: 10px;
}*/
.claim-btn{

  position: absolute;

  height: 250px;
  right: 600px;
  bottom: 170px;
  background-color: transparent;
  border: none;
  font-size: 34px;
  cursor: pointer;
}
.l2{
  margin-left:25px;
}
.l3{
  margin-left:55px;
}
.l4{
  margin-left:95px;
}
.l5{
  margin-left:135px;
}
.claim-txt-mobile{
  display: none;
  position: absolute;
  margin-top: 50%;
  font-size: 18px;
  color: white;
}

/* mobile-------------------------------------------------------------------------*/
/* mobile-------------------------------------------------------------------------*/
/* mobile-------------------------------------------------------------------------*/
/* mobile-------------------------------------------------------------------------*/
/* mobile-------------------------------------------------------------------------*/
/* mobile-------------------------------------------------------------------------*/
/* mobile-------------------------------------------------------------------------*/

@media (max-width: 1200px) {

@keyframes hoverAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
@keyframes fadeInFromBottom {
  from {
      opacity: 0;
      transform: translateY(80%);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
@keyframes fadeInLeftToRight {
  from {
      opacity: 0;
      transform: translateX(20%); /* Start from right */
  }
  to {
      opacity: 1;
      transform: translateX(-20%); /* End at left */
  }
}
@keyframes fadeInRightToLeftSmall {

    from {
        opacity: 0;
        transform: translateX(-100%); /* Start from right */
    }
    to {
        opacity: 1;
        transform: translateX(0); /* End at left */
    }

}
@keyframes typing {
  from { width: 0; }
  to { width: 40%; }
}


/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgb(0, 0, 0); }
}
@keyframes fadeIn {
  from {
      opacity: 0;

  }
  to {
      opacity: 1;

  }
}




*{
  margin:0;
  font-family: "Freeman", sans-serif;
  font-weight: 400;
  font-style: normal;
  color:#3E48CC;

  /* color: #F9D190; */

}

/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*-----------------------------------------IO-----------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/* .section-splitter {
  position: relative;
  height: 100px;
  background: linear-gradient(180deg, #ffffff, #3E48CC);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
} */


/* .section-splitter::before {
  left: -100px;
}

.section-splitter::after {
  right: -100px;
} */


/* .live-indicator {
  color:#F9D190;
  position: absolute;
  display: flex;
  align-items: center;
  font-family: Arial,3sans-serif;
  margin-left: 81%;
  margin-top: 0.3%;
}

.live-dot {
  height: 10px;
  width: 10px;
  background-color: rgb(9, 255, 0);
  border-radius: 50%;
  margin-right: 5px;
  animation: fadeInOut 2s linear infinite;
} */

@keyframes fadeInOut {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}
.gif-container {
  width: 100%;
  overflow: hidden;
  position: absolute;  /* Changed from relative to absolute */
  margin-bottom: 0;
  height: 50px;
  background-color: #BDBDBD;
  bottom: 0;  /* Positions the element at the bottom */
  left: 0;  /* Aligns the element from the left edge */
}


.moving-gif {
  position: absolute;
  /* margin-top: 164px; */
  width: 50px;
  animation: moveGif 3s linear infinite;
}

@keyframes moveGif {
  0%, 100% {
    transform: translateX(0) scaleX(1);
  }
  49% {
    transform: translateX(calc(100vw - 100px)) scaleX(1);
  }
  50% {
    transform: translateX(calc(100vw - 100px)) scaleX(-1);
  }
  99% {
    transform: translateX(0) scaleX(-1);
  }
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid rgb(0, 0, 0); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  letter-spacing: .25em; /* Adjust as needed */
  animation:
    typing 2.5s steps(22, end),
    blink-caret .75s step-end infinite;
}

.fade-in-right {
  animation: fadeInRightToLeft 3s ease forwards;
}
.fade-in-right-small {
  animation: fadeInRightToLeftSmall 3s ease forwards;
}
.fade-in-left {
  animation: fadeInLeftToRight 3s ease forwards;
}
.nav{
  display: block;

  display: flex;
  width: 90%;
 
  align-items: center;
  flex-direction: row;
  list-style: none;
  position: absolute;
  gap:10px;
  background-color: transparent;
  

  padding: 1%;

}
.dexes {
  position: fixed;
  z-index: 20;
  width: 65%;  /* Width of the container */
  background-color: transparent;
  color: white;
  

  top: 95%; /* Position the top edge of the element at the middle of the screen */
  left: 50%; /* Position the left edge of the element at the middle of the screen */
  transform: translate(-50%, -50%); /* Shift the element back by half its width and height */

  display: flex;
  align-items: center; /* Center items vertically within the container */
  justify-content: center; /* Center items horizontally within the container */
}


 .dexscreener {
  margin-top: 0px; /* Consistent margin */
  
}

.dex {
  height: 35px; /* Height for dex */
 
}

.dexscreener {
  height: 26px; /* Smaller height for dexscreener */
 
  border-radius: 0 15px 0 15px; /* Smooth corners on right side */
}
.socials-nav{

  
  display: flex;
  flex-direction: row;
  gap: 25px;
  font-size: 18px;
  list-style: none;
  position: absolute;
  right: 5%;
  top: 30px;
  
}
.socials-li{
  color: white;
  font-family: "Faster One", system-ui;
  text-decoration: none;
  list-style: none;
}
.link{
  /* color: #3E48CC; */
  color: white;
  text-decoration: none;
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Faster One", system-ui;
  font-weight: 400;
  font-style: normal;

  /* z-index: 20; */
}
/* .catpaw:hover{
  cursor: url('./img/cursor.cur'), auto;
} */
.welcome-bg{
  position: absolute;
  background-image: url("./img/welcomeAccelerate.png");
  background-repeat: no-repeat;
  height: 30%;
  width: 70%;
  left: 27%;
  top: 30%;
  background-size: 100%;
  background-position: 10px 5px;
  z-index: -4;
}
.have-you-ever-txt{
  position: absolute;
  margin-top: 105%;
  margin-left: 30%;
  color: black;
  font-family: sans-serif;
  font-size: 10px;
}
.content{
  height: 100vh;

}
.buy-btn{
  /* border: 1px solid red; */
  /* background-image: url("./img/ring.png");
  background-repeat: no-repeat; */
  cursor: pointer;
  /* background-size: 80%;
  background-position: 10px 5px; */
  position: absolute;
  font-family: "Faster One", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  right: 150px;
  height:40px;
  width: 180px;
  top: 20px;
  border: none;
  border-radius: 25px;
  border: 3px solid white;
  background-color: transparent;
  color: white;
}

/* .buy-btn:hover{
  cursor: url('./img/cursorbuy.cur'), auto;
} */

.mob-buy-btn{
  display: none;
}

.io-logo{
  display: none;
  height: 50px;
  border-radius: 50px;
  position: absolute;
  right: 5%;
  top: 10px
}

.main-land{
  background-image: url('./img/welcomeBackground.png');
  background-color: #3676eda9;
  background-repeat: no-repeat;
  background-size: 50%;
  height: 100vh;
  background-position:0% 60%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 660px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
   /* box-shadow: rgb(0, 0, 0) 10px 135px 50px 10px inset;  */
}

.content-header{
  color: transparent;
}
.io-front{

  margin-top: 35%;
}
.io-header{
  position:absolute;
  text-align: center;
  font-family: "Faster One", system-ui;
  font-weight: 400;
  font-style: normal;
  color:white;
  /* color: #F9D190; */
  /* color: #EC6906; */
  font-size: 5px;
  /* width: 10%; */
  margin-left: 8%;
  margin-top: 60%;
}
.welcome-img{
  background-image: url("./img/sanic.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 450px;
  width: 450px;

  margin-left: 30%;

}
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*--------------------------------------TOKENOMICS------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/

.tokenomics{
  position: relative;
  background-color: #3E48CC;
  z-index: -3;

  height: 2100px;
  /* display: flex;
  flex-direction: row; */
}
.content-header{
 /* border: 1px solid red; */
 /* height: 10px; */
}
#Roadmap{
  position: absolute;
  margin-top: 0%;

}
.tokenomics-txt, .tokenomics-header{
  color: #ffffff;
  width: 90%;
  list-style: none;
}

.tokenomics-total{
  margin-top: -650px;
}
.tokenomics-content{
  position: relative;
  display: flex;
  text-align: center;
  flex-direction: column;
  border: 3px solid white;
  margin-bottom: 3%;
  border-radius: 0 15px 0 15px;
  padding: 0px 0px 150px 0px;
  margin-left: 0;
  width: 98%;
   height: 250px;
  z-index: 1;
  box-shadow: rgb(25, 31, 92) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

}


.tokenomics-img-one{
position: absolute;
  background-image: url("./img/samic.png");
  background-size: 80%;
  background-repeat: no-repeat;
  height: 35%;
  width: 50%;

  margin-top: 70%;
  margin-left: 30%;




}
.tokenomics-img-two{
  position: absolute;
  background-image: url("./img/creepy.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 50%;
  width: 45%;

  margin-top: 225px;
  margin-left: 30%;


}
.tokenomics-img-three{
  position: absolute;
  background-image: url("./img/Sanic.webp");
  background-size: 100%;
  background-repeat: no-repeat;


  height: 70%;
  width: 55%;

  margin-top: 200px;
  margin-left: 20%;
}
.tokenomics-img-four{
  position: absolute;
  background-image: url("./img/somic-scream.png");
  background-size: 100%;
  background-repeat: no-repeat;


  height: 45%;
  width: 60%;

  margin-top: 220px;
  margin-left: 15%;



}
.t-txt-one{
 font-size: 15px;
  color: white;
 padding: 2%;
 width: 90%;
 margin-left: 10%;
}
.t-txt-two{

  font-size: 15px;
  color: white;
 padding: 2%;
  width: 90%;
  margin-left: 10%;
}
.t-txt-three{
  font-size: 15px;
  color: white;
 padding: 2%;
 width: 90%;
  margin-left: 10%;
}
.t-txt-four{
  font-size: 15px;
  color: white;
 padding: 2%;
 width: 90%;
 margin-left: 10%;
}
.tokenomics-header{
  padding: 4% ;
}
.tokenomics-txt {
  font-size: 12px;
  padding: 3% 0% 0% 0%;
}


.tokenomics-img-bg {
  position: absolute;
  background-image: url("./img/soomic.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 90%;
  width: 90%;
  margin-left: 10px;
  margin-top: 10%;
  opacity: 0;
  z-index: -1;
}
.roadmap-chat-img{
  display: none;
  position: absolute;
  background-image: url("./img/chatsquare.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 90%;
  width: 90%;
  margin-top: 185%;
  z-index: 10;
  text-align: center;

}
.roadmap-txt{
  display: none;
  font-size: 90px;
  margin-top: 100%;
  color: red;

}

.img-container {
  position: relative;
  height: 945px; /* Adjust height as needed */
  overflow: hidden;
}

.animation-start {
  opacity: 1;
  animation: fadeInFromBottom 1s forwards;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}



.catpaw{

}

/* .wallet-btn{
  display: none;
  background-color: transparent;
  width: 150px;
  height: 40px;
  border-radius: 20px;
} */







#io-font-txt{
  display: block;
  margin-top: 0.5%;
  font-size: 50px;
  padding-right: 0%;
}
.mob-tokenomics{
  display: none;
}

/* .gif-fade-one{
  background: linear-gradient(180deg, rgba(189,189,189,1) 0%, rgba(189,189,189,1) 40%, rgba(162,159,185,1) 61%, rgba(140,134,182,1) 71%, rgba(56,37,168,1) 100%);
  height: 50px;
  width: 100%;
} */
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*--------------------------------------HOW-IT-WORKS----------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
@keyframes moveToRight {
  from {

      transform: translateX(0%); /* Start from right */
  }
  to {

      transform: translateX(200%); /* End at left */
  }
}
.howitworks{

    position: relative;
    width: 100%;
    height: 1100px;
    background-color: #2E1E8A;
    background-image: url('./img/howitworks-bg1.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 100% 0%;
    z-index: 2;
    box-shadow: inset 0 0 35px 5px rgba(0,0,0,0.25), inset 0 2px 1px 1px rgba(255,255,255,0.9), inset 0 -2px 1px rgba(0,0,0,0.25);


  }
.fade-bg{
  position: absolute;
    width: 100%;
    height: 1100px;
    background-color: #1f536bd3;
    z-index: 1;
}


 .howitworks-header{
  display: none;
  position: relative;
  margin-top: 70%;
  margin-left: 0px;
  padding: 10%;
  color: white;
  z-index: 10;
}
.how-header{
  position: absolute;
  margin-left: 3%;
  margin-top: 3%;
  color: white;

}
.howitworks-content{
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 50%;
  z-index: 10;
}
.how-sort {
  display: flex;
  flex-direction: column;
  margin-top: 1%;
  margin-left: 0px; /* Initially pushed to the left */
  transition: margin-left 2s ease;

}

/* .proposal-container:hover ~ .how-sort, .how-header {
  margin-left: 40px; /* Reset to 0 on hover to move it right

} */
.howitworks-wrapper{
  display: flex;
  flex-direction:column;
  margin-left: 0%;
  margin-bottom: 0px;


}
.howitworks-container{
  height: 50%;
  width: 98.5%;
  border-radius: 0 15px 0 15px;
  margin-left: 0;
  text-align: center;
  margin-bottom: 5%;
  border: 2px solid white;
  background-color: transparent;
  box-shadow:#001525a2 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.howitworks-txt{
  color: white;
  padding: 0% 0% 7% 0%;
  font-size: 12px;
}

.proposal-container{
  display: none;
  height: 60px;
  width: 280px;
  margin-top: 20px;

  transition: all 0.5s ease;
  overflow: hidden;
}

.proposal-container:hover{
  height: 1250px;
  width: 730px;

  cursor: pointer;
}

.token-dist-container{

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Use center here for horizontal alignment */
  width: 100%;
  height: 70%;
 
  left: 50%; /* Center horizontally */
  top: 15%; 
  transform: translate(-50%, -50%); /* Adjust position to true center */
  opacity: 1;
  transition: opacity 0.5s ease-out;
  margin: 0; /* Remove any margins that could disrupt centering */
  
}

.chart-container {
  position: relative;
  display: none;
  height: 300px; 
}

.pie-chart {
  position: absolute;
  top: 50px;  
  left: 0;
  right: 0;
  bottom: 0;
}
.chart-img{
  background-image: url("./img/hehe-removebg-preview.png");
  background-repeat: no-repeat;
   background-size: 90%;
   margin-top: 80%;
   height: 220px;
   width: 220px;
   margin-left: 0px;
}

.proposal{
  background-image: url("./img/proposal.jpg");
  background-repeat: no-repeat;
   background-size: 80%;

   height: 1250px;
   width: 700px;
   margin-left: 75px;
   border-radius: 0 15px 0 15px;
  padding: 10px;

  transition: opacity 0.5s ease;
  opacity: 0;
}

.proposal-container:hover .proposal{
  opacity: 1;

}

.howitworks-tokenomics{
  width: 30%;
  border-radius: 0 15px 0 15px;
  margin-left: 10px;
  border: 2px solid white;
}
.proposal-container:hover ~ .howitworks-tokenomics{
  width:  40px; /* Reset to 0 on hover to move it right */
  height: 40px;
  margin-top: 90%;
  transition: opacity 0.5s ease;
}



/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*-------------------------------------SOCIALS----------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/


/* Apply this style to the parent container of .collabs-container */
.social {
  background-color: rgb(11, 11, 77);
  position: relative;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 250vh; /* Full viewport height */
  
}
.social {
  height: 250vh; 
  background-color: rgba(18, 18, 85, 0.932);
  position: relative;
  display: flex;
  flex-direction: column;
 
  align-items: center; /* Center vertically */
 
  
}
.social-header{
  font-family: "Bangers", system-ui;
  margin-bottom: 10px;
  color: white;
  font-size: 30px;
  margin-top: 6%;
}
.bridge-header{
  font-family: "Bangers", system-ui;
  margin-bottom: 1%;
  color: white;
  font-size: 30px;
  margin-top: 50px;
}
.howtobuy-container{
  display: flex;
  flex-direction: column;
  gap:0px;
  align-items: center;
 
  
  
}
.howtobuy-step{
  border: 1px solid white;
  height: 45%;
  border-radius: 0 15px 0 15px;
  width: 90%;
  box-shadow: #00042e 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

 
 
}
.bridge{
  height: 50px;
  text-align: center;
  margin-top: 5%;
}
.howtobridge-txt{
  color: white;
  padding: 22px;
  font-size: 12px;
}
.howtobridge-iftxt{
  color: white;
  position: relative;
  text-align: center;
  top:0.1%;
  font-size: 12px;
}
.howtobridge-iftxt a{
  color: white;
}
.howtobuy-header{
  text-align: center;
  font-family: "Bangers", system-ui;
  color: white;
  margin-top: 0px;
  font-size: 18px;
}
.howtobuy-txt{
  color: white;
  padding: 20px;
  font-size: 12px;
}
.howtobuy-list{
  margin-left:10px;
  padding-bottom: 10px;
  
}
.howtobuy-list li a{
  font-size: 12px;
  color: white;
  text-decoration: none ;
}
.howtobuy-list li a:hover{
  color: #444bad;
}


.urnotfast {
  position: absolute;       /* Position relative to the nearest positioned ancestor */
  background-image: url("./img/urnotfast.png");
  background-size: 110%;    /* Cover the div fully with the background */
  background-repeat: no-repeat;
  height: 350px;            /* Fixed height */
  width: 350px;             /* Fixed width */

  margin: 0;
                  /* Align to the right side of the container */
  bottom: 0;                /* Align to the bottom of the container */
  z-index: 1;              /* Ensure it's above other content (adjust as necessary) */
  right: auto;  /* Resets the right property */
  left: 50%;    /* Move to 50% from the left */
  transform: translateX(-50%);  /* Shift it back by 50% of its own width to center it */
}

.uwu {
  display: none;
  position: absolute;           /* Position relative to the nearest positioned ancestor */
  background-image: url("./img/UWU.png");
  background-size: cover;       /* Cover the div fully with the background */
  background-repeat: no-repeat;
  top: 0;                       /* Align to the top of the container */
  left: 0;                      /* Align to the left of the container */
  width: 400px;                 /* Specify width */
  height: 500px;                /* Specify height */
  z-index: 10;                  /* Ensure it's above other content (adjust as necessary) */
}

#Socials{
  position: absolute;
  top: 0;    /* Align the top edge of the element with the top of its nearest positioned ancestor */
  left: 0; 
  
}




  .collabs-container {
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically if there's extra space */
    gap: 0px; /* Adjust gap for better spacing in mobile view */
    padding: 0; /* Reset padding */
    margin: 0 auto;
  }

  .collabs-sort {
    flex-direction: column; /* Ensure this remains a column but adjust as necessary */
    width: 100%; /* Full width to use the whole container width */
    align-items: center; /* Center the collabs inside each sort */
    
  }

  .collab {
    width: 80%; /* Reduce width to better fit mobile screens */
    justify-content: center; /* Center the contents of each collab */
    padding: 0; /* Reset padding */
    margin: 10px auto;
  }

  .collab-txt {
    text-align: center; /* Center the text within each collab */
  }



.collabOne{
  border-radius: 50%;
  background-image: url(./img/collabOne.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 2px;
  
}
.collabTwo{
  border-radius: 50%;
  background-image: url(./img/coinmarketcap.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 2px;

}
.collabThree{
  border-radius: 50%;
  background-image: url(./img/collabThree.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 2px;
  
}
.collabFour{
  border-radius: 50%;
  background-image: url(./img/collabFour.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 2px;

}
.collabFive{
  border-radius: 50%;
  background-image: url(./img/collabFive.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 2px;
  
}
.collabSix{
  border-radius: 50%;
  background-image: url(./img/collabSix.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 2px;
}
.collabSeven{
  border-radius: 50%;
  background-image: url(./img/collabSeven.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 2px 2px 2px 2px;
  
}
.collabEight{
  border-radius: 50%;
  background-image: url(./img/collabFour.png);
  height: 45px;
  width: 45px;
  background-size: 100%;
  background-repeat: no-repeat;

}
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*--------------------------------------AIRDROP-SEC-----------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------*/
#JoinUs{
  position: absolute;
  top: 0;
  left: 0;
}
.last-container {
  position: relative;
  
  height: 100vh;
  background-image: url("./img/sanic-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 2s linear;
  background-color: #2a308a;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; 
  transform: translateZ(0); 


  
}

.airdrop-container {
  position: absolute;
  background-image: url("./img/chat1.png");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: 10; 
  
  margin:0% 0% 0% 0%;


 
}

.claim-txt {
  position: absolute; /* Ensure it's positioned relative to its nearest positioned ancestor */
  width: 200px;
  color: black;
  font-size: 20px;
  z-index: 20;
  text-align: center;
  padding: 0;
 
  top: 25%; /* Position it 20% from the top of its parent container */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Offset it by half its width to truly center it horizontally */
}








.btn-letter{
  margin-top: 0;
  color: white;
}
/* .claim-btn:hover{

} */
.hidden-info {
  position: absolute;
  background-image: url("./img/chat1.png");
  background-repeat: no-repeat;
  background-size: 100%;

  display: none;  /* Initially hide the div */
  right: 0px;

  padding: 8px; /* Some padding */

  height: 10px;
  width: 10px;
  margin-top: 175px; /* Space between button and the div */
}
.hidden-txt{
  font-family: "Eater", serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  font-size: 32px;
  padding-top: 40px;
}

.claim-btn:hover + .hidden-info {
  display: block; /* Show the div when hovering over the button */
}
/*
.img-airdrop{
  background-image: url('./img/airdrop.webp');
  /* background-color: black;
  height: 600px;
  width: 600px;
  background-size: 100%;
  border-radius: 50%;
  margin-top: 150px;
  margin-left: 1000px;
  background-repeat: no-repeat;
} */

.socials{

font-family: "Bangers", system-ui;
  margin: 0;
  margin-left: 3px;
  margin-right: 3px;
  color: rgb(74, 131, 255);
  text-decoration: none;
  
}

.rights{
  position: absolute;
  background-color: transparent;
  color: white;
 
  
  bottom: 0;
  width: 100%;
  z-index: 20;
  /* text-align: center; */
}
.rights-txt{
  position: absolute;
  background-color: transparent;
  font-size: 18px;
  margin-top: 0.5%;
  margin-left: 1%;

}

/* .airdrop-txt{
  position: absolute;
  color: #F9D190;
  width: 35%;
  margin-left: 20%;
  margin-top: -25%;
  font-size: 45px;
} */



/* .sneaky-img{
  position: absolute;
  background-image: url("./img/sneaky.jpg");

  background-size: 100%;
  background-repeat: no-repeat;
  height: 150px;
  width: 250px;
  right: 2%;
  margin-top: 31.5%;



} */


/* .airdrop-container {
  margin-left: 30%;
  text-align: center;
  padding: 20px;
  border-radius: 50%;
  text-align: center;
  height: 55vh;
  width: 55vh;
  padding: 5%;
}
.airdrop-container h1{
  margin-top: 150px;
}

.airdrop-container input {
  margin-right: 10px;
}*/
.claim-btn{

  position: absolute;

  height: 250px;
  right: 600px;
  bottom: 170px;
  background-color: transparent;
  border: none;
  font-size: 34px;
  cursor: pointer;
}
.l2{
  margin-left:25px;
}
.l3{
  margin-left:55px;
}
.l4{
  margin-left:95px;
}
.l5{
  margin-left:135px;
}

}